import { types } from 'mobx-state-tree';

const BookingUtilities = types
  .model('BookingUtilities', {
    showEventModal: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setShowEventModal() {
      self.showEventModal = !self.showEventModal;
    },

    toggleLoading() {
      self.isLoading = !self.isLoading;
    }
  }));

export default BookingUtilities;
