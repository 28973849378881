import { BookingsState, BookingUtilities } from '@app_modules/Bookings/model';
import { EventsState, EventUtilities } from '@app_modules/Events/model';
import { RoomsState, RoomUtilities } from '@app_modules/Rooms/model';
import { types } from 'mobx-state-tree';

const AppStore = {
  events: types.optional(EventsState, {}),
  EventUtilities: types.optional(EventUtilities, {}),
  rooms: types.optional(RoomsState, {}),
  RoomUtilities: types.optional(RoomUtilities, {}),
  bookings: types.optional(BookingsState, {}),
  BookingUtilities: types.optional(BookingUtilities, {})
};

export default AppStore;
