import { types } from 'mobx-state-tree';

const EventsModel = types
  .model('EventsModel', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    color: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default EventsModel;
