const BOOKINGS_ROUTE = '/bookings';
const EVENTS_ROUTE = '/events';
const ROOMS_ROUTE = '/rooms';

const Routes = {
  BOOKINGS_ROUTE,
  EVENTS_ROUTE,
  ROOMS_ROUTE
};
export default Routes;
