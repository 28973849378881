import React from 'react';
import { BookOutlined, BellOutlined, HomeOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <BookOutlined />,
    route: Routes.BOOKINGS_ROUTE,
    menuName: 'Bookings'
  },
  {
    key: '4',
    icon: <BellOutlined />,
    route: Routes.EVENTS_ROUTE,
    menuName: 'Events'
  },
  {
    key: '5',
    icon: <HomeOutlined />,
    route: Routes.ROOMS_ROUTE,
    menuName: 'Rooms'
  }
];

export default AppMenus;
