import { types } from 'mobx-state-tree';

const RoomUtilities = types
  .model('RoomUtilities', {
    showAddOrUpdateRoomModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), ['name', 'capacity']),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateRoomModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateRoomModal = !self.showAddOrUpdateRoomModal;
    },

    setUpdateId(id) {
      self.updateId = id;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    }
  }));

export default RoomUtilities;
