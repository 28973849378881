import React, { Suspense } from 'react';
import { Form } from 'antd';
/**APP IMPORTS */

import { EventHeader, EventTable } from '@app_modules/Events/components';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

function Events() {
  const [form] = Form.useForm();
  return (
    <div>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <EventHeader form={form} />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <EventTable form={form} />
      </Suspense>
    </div>
  );
}

export default Events;
