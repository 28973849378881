import { applySnapshot, cast, flow, types } from 'mobx-state-tree';
import BookingsModel from './BookingsModel';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import axios from 'axios';

const API_PATH = '/api/bookings';

export default types
  .model('BookingsState', {
    state: types.optional(types.array(BookingsModel), []),
    stateCopy: types.optional(types.array(BookingsModel), []),
    newlyDropped: types.optional(BookingsModel, {}),
    single: types.optional(BookingsModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),

    createOrUpdateBooking: flow(function* (data, isUpdate = false) {
      try {
        let response = yield axios.post(`${API_PATH}`, data);
        response.data.new = true;
        self.stateCopy.push(response.data);
        Object.assign(self.single, response.data);
      } catch (error) {
        console.log(error, 'dfdfdfdf');
        return [null, error];
      }
    }),

    clearSingle() {
      applySnapshot(self.single, {});
    },

    getBookings: flow(function* (e) {
      try {
        let { start, end } = e;
        let { data } = yield axios.get(`${API_PATH}`, {
          params: { start, end }
        });
        self.state = [];
        self.stateCopy = [];

        self.state = cast(data);
        self.stateCopy = cast(data);

        return [data, null];

        //  return res.data;
      } catch (error) {
        console.log(error, 'error');
        return [null, error];
      }
    }),

    getBookingData: flow(function* (id) {
      let {
        data: { data }
      } = yield axios.get(`${API_PATH}/get_full_data`, { params: { id } });

      Object.assign(self.single, data);
    })
  }))
  .views((self) => ({}));
