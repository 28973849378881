import EventsModel from '@app_modules/Events/model/EventsModel';
import UsersModel from '@core_modules/UserManagement/model/UsersModel';
import { types } from 'mobx-state-tree';

const BookingsModel = types
  .model('BookingsModel', {
    id: types.optional(types.integer, 0),
    event_id: types.optional(types.integer, 0),
    user_id: types.optional(types.string, ''),
    room_id: types.optional(types.maybeNull(types.integer), 0),
    title: types.optional(types.string, ''),
    color: types.optional(types.string, ''),
    resourceId: types.optional(types.string, ''),
    start: types.optional(types.maybeNull(types.string), ''),
    new: types.optional(types.boolean, false),
    end: types.optional(types.maybeNull(types.string), ''),
    User: types.optional(types.maybeNull(UsersModel), {}),
    Event: types.optional(types.maybeNull(EventsModel), {})
  })
  .views((self) => ({}))
  .actions((self) => ({
    UPDATE: (values) => {
      self = Object.assign({}, values);
    }
  }));

export default BookingsModel;
