import { types } from 'mobx-state-tree';

const RoomsModel = types
  .model('RoomsModel', {
    id: types.optional(types.integer, 0),
    name: types.optional(types.string, ''),
    capacity: types.optional(types.integer, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default RoomsModel;
