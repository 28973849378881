import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

/**APP IMPORTS */
import { BookingTabs } from '@app_modules/Bookings/components';

/**CORE IMPORTS */
import { ExactTitle, FallBackLoaders } from '@core_common/components';

function Bookings() {
  const { t } = useTranslation('common');

  return (
    <div>
      <ExactTitle level={3} text={t('Bookings')} />

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <BookingTabs />
      </Suspense>
    </div>
  );
}

export default Bookings;
